<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
    :fill="iconColor"
  >
    <title
      :id="iconName"
      lang="en"
    >{{iconName}} icon</title>
    <g >
      <slot />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 38
    },
    height: {
      type: [Number, String],
      default: 38
    },
    iconColor: {
      type: String,
      default: 'grey'
    },
    viewBox: {
      type: String,
      default: '0 0 50 50'
    }
  },
  name: 'IconBase'
}
</script>
