<template>
    <div class="themeSwitch"  @click="changeTheme()" title="theme">
       <div class="themeSwitchBorder" > 
        <div class="moon" v-show="!lightTheme"><div class="shadow"></div><div class='forBorder'></div></div>
        <transform>
            <div class="sole" v-show="lightTheme"></div>
        </transform>
       </div>  
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ThemeSwitch',
  data () {
    return {
     
    }
  },
  computed: {
    ...mapState({
      lightTheme: state => state.Status.lightTheme,
    }),
  },
  methods: {
    changeTheme () {
      this.$store.commit('Status/changeTheme')
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.themeSwitch{
    width: min(78.5px, 9.81vw, 9.81vh);
    height: min(32.4px, 4.05vw, 4.05vh);
    margin-right: 10px;
}
.themeSwitchBorder{
    width: min(78.5px, 9.81vw, 9.81vh);
    height: min(32.4px, 4.05vw, 4.05vh);
    border-radius: min(15px,2.025vw,2.025vh);
    position:absolute;
    z-index: 3;
}
.sole {
    width:  min(31px, 3.38vw, 3.38vh);
    height: min(31px, 3.38vw, 3.38vh);
    background-color: yellow;
    border-radius: 50%;
    border: min(1px, 0.125vw, 0.125vh) solid orange;
}
.moon {
    width:  min(31px, 3.38vw, 3.38vh);
    height: min(31px, 3.38vw, 3.38vh);
    background-color: white;
    border-radius: 50%;
    border: min(1px, 0.125vw, 0.125vh) solid grey;
    position: absolute;
    margin-left: min(47.5px, 6.43vw, 6.43vh);
    margin-bottom: 10px;
    z-index: 2;
}
.shadow{
    width:  min(31px, 3.38vw, 3.38vh);
    height: min(31px, 3.38vw, 3.38vh);
    background-color: black;
    border-radius: 50% 50% 50% 50%;
    position: absolute;
    margin-left: 5px;
    margin-top: -5px;
    z-index: 1;
}
.forBorder{
    width:  min(31px, 3.38vw, 3.38vh);
    height: min(31px, 3.38vw, 3.38vh);
    border-radius: 50%;
 
    position:absolute;
    z-index: 4;
    opacity: 0.7;
}    
</style>
