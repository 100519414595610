<template>
   <nav id="nav" class="header-right-bottom">
          <router-link class="nav-item" to="/"><h3 :class="!lightTheme ?'dark'  :'light'">{{menuTitles.home}}</h3></router-link>
          <router-link class="nav-item" to="/projects"><h3 :class="!lightTheme ?'dark'  :'light'">{{menuTitles.projects}}</h3></router-link>
          <router-link class="nav-item" to="/photos"><h3 :class="!lightTheme ?'dark'  :'light'">{{menuTitles.photos}}</h3></router-link>
          <router-link class="nav-item" to="/videos"><h3 :class="!lightTheme ?'dark'  :'light'">{{menuTitles.video}}</h3></router-link>
          <router-link class="nav-item" to="/others"><h3 :class="!lightTheme ?'dark'  :'light'">{{menuTitles.others}}</h3></router-link>
          <router-link class="nav-item" to="/contacts"><h3 :class="!lightTheme ?'dark'  :'light'">{{menuTitles.contacts}}</h3></router-link>
    </nav>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'NavBar',
  props: ['menuTitles'],
  computed: {
    ...mapState({
      lightTheme: state => state.Status.lightTheme,
    })
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-right-bottom{
  width: 99%;
  height: 33%;
  padding: 0 1% 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h3{
    font-family: Romanus,serif;
    font-size: 34px;
    color: grey;
    font-weight: 400;
}
h3:hover{
  font-size: 2.2vh;
}
.light:hover {
  color: rgb(29, 20, 20);
}
.dark:hover {
  color: white;
}

.nav-item{
  margin-right: 10px;
  text-decoration: none;
  color: #2c3e50;
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.router-link-active{
  display: none;
}
@media only screen and (max-width: 320px) {
  h3{
    font-size: min(10px, 2vh);
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px){
  h3{
    font-size: min(14px, 2vh);
  }
}
@media only screen and (min-width: 481px) and (max-width: 499px){
  h3{
    font-size: min(15px, 2vh);
  }
}
@media only screen and (min-width: 500px) and (max-width: 549px){
  h3{
    font-size: min(16px, 2vh);
  }
}
@media only screen and (min-width: 550px) and (max-width: 640px){
  h3{
    font-size: min(17px, 2vh);
  }
}
@media only screen and (min-width: 641px) and (max-width: 770px){
  h3{
    font-size: min(19px, 2vh) ;
  }
}
@media only screen and (min-width: 771px) and (max-width: 980px){
  h3{
    font-size: min(21px, 2vh);
  }
}
@media only screen and (min-width: 981px) and (max-width: 1200px){
  h3{
    font-size: min(24px, 2vh);
  }
}
@media only screen and (min-width: 1201px){
  h3:hover{
    font-size: 35px;
  }
}
</style>
