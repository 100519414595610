<template>
    <div id="title" class="header-left">
        <div v-if="!lightTheme & currPage==='projects'" class="center" style="color:white;">
          <router-link to="/projects" class="hubLink">
            <h2>Projects</h2><h2 class="hub">hub</h2>
          </router-link >
        </div>       
        <h1  v-else class="center">{{myname}}</h1>   
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TitlePlace',
  props: ['myname'],
  components: {

  },
  computed: {
    ...mapState({
      langvidge: state => state.Status.langvidge,
      lightTheme: state => state.Status.lightTheme,
      currPage: state => state.Status.currPage
    })
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
  margin: 0;
  font-family: 'Romanus', serif;
  font-size: 60px;
  font-weight: bold;
  margin-left: 5px;
}
.header-left{
  display: flex;
  width: 99%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 1%;
}
.hubLink {
  text-decoration: none;
  display: flex;
  color: white;
  align-items: center;
}
.hub{
  background-color:#ffa31a;
  color: black;
  border-radius: 3px;
  padding: 6px;
  margin-left: 5px;
}

@media only screen and (max-width: 320px) {
  h1{
    font-size: min(20px, 5vh);
  }
  h2{
    font-size: min(8px, 3vh);
  }
  .hub{
    padding: 2px 4px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px){
  h1{
    font-size: min(30px, 5vh);
  }
  h2{
    font-size: min(9px, 4vh);
  }
  .hub{
    padding: 3px 5px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 391px) and (max-width: 480px){
  h2{
    font-size: min(10px, 4vh);
  }
  .hub{
    padding: 3px 5px;
    margin-left: 3px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 499px){
  h1{
    font-size: min(31px, 5vh);
  }
  h2{
    font-size: min(11px, 5vh);
  }
  .hub{
    padding: 4px 6px;
    margin-left: 4px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 549px){
  h1{
    font-size: min(32px, 5vh);
  }
  h2{
    font-size: min(12px, 6vh);
  }
  .hub{
    padding: 4px 6px;
    margin-left: 4px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 640px){
  h1{
    font-size: min(33px, 5vh);
  }
  h2{
    font-size: min(13px, 7vh);
  }
  .hub{
    padding: 3px 6px;
    margin-left: 4px;
  }
}
@media only screen and (min-width: 641px) and (max-width: 770px){
  h1{
    font-size: min(38px, 6vh) ;
  }
  h2{
    font-size: min(14px, 8vh);
  }
  .hub{
    padding: 3px 6px;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 771px) and (max-width: 980px){
  h1{
    font-size: min(42px, 7vh);
  }
  h2{
    font-size: min(16px, 9vh);
  }
  .hub{
    padding: 3px 6px;
    margin-left: 4px;
  }
}
@media only screen and (min-width: 981px) {
  h1{
    font-size: min(47px, 7vh);
  }
  h2{
    font-size: min(17px, 10vh);
  }
  .hub{
    padding: 3px 6px;
    margin-left: 4px;
  }
}
@media only screen and (min-width:1201px) {
  h2{
    font-size: min(26px, 16vh);
  }
  .hub{
    padding: 3px 6px;
    margin-left: 4px;
  }
}
</style>
