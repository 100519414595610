<template>
  <main class="main column" :style="lightTheme ? 'background-color: white;' : 'background-color: black;'">
    <Header />
    <router-view/>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/HeaderBlock.vue'
export default {
  name: 'MainPage',
  components: {
    Header
  },
  data () {
    return {
      myInterval: 0,
      firstInterval: 0
    }
  },
  computed: {
    ...mapState({
      lightTheme: state => state.Status.lightTheme,
    }),
  },
  mounted () {
    this.$store.commit('Status/changeCurrentMeasurment', [window.innerHeight, window.innerWidth]);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.myEventHandler);
    this.firstInterval = setInterval(this.changePulse, 50);
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.myEventHandler);
    clearInterval(this.firstInterval);
  },
  methods: {
    changeMagicImg () {
      this.$store.commit('Photos/changeVisibilityMagicImg')
    },
    changeFirstImg () {
      this.$store.commit('Current/changeVisibilityFirstImg')
    },
    myEventHandler () {
      this.$store.commit('Status/changeCurrentMeasurment', [window.innerHeight, window.innerWidth])
    },
    onScroll () {
      this.$store.commit('Status/changeCurrentScroll', window.pageYOffset)
    },
    changePulse(){
      this.$store.commit('Status/changePulse')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main{
  flex-grow: 1;
  background-color:white;
}
</style>
