export default {
  namespaced: true,
  state: {
    videoBank: [
      {
        id: 'v00001',
        sempl: '00001',
        title: 'title',
        shortname: {
          ru: 'Прибытие поезда',
          en: 'Train is arraiving'
        },
        shortDescription: {
          ru: 'Традиционно первый ролик -прибытие поезда',
          en: ' This is traditional first video'
        }
      },
      {
        id: 'v00002',
        sempl: '00002',
        title: 'title',
        shortname: {
          ru: 'Робот-курьер',
          en: 'Robot-courier'
        },
        shortDescription: {
          ru: 'Этот робот от Яндекса может доставлять небольшие грузы',
          en: 'This robot can deliver small packages'
        }
      },
      {
        id: 'v00003',
        sempl: '00003',
        title: 'title',
        shortname: {
          ru: 'Робот-мерчендайзер',
          en: 'Robot-merch'
        },
        shortDescription: {
          ru: 'Этот робот проверяет выкладку в магазине',
          en: 'This robot checks display in the store'
        }
      }
    ]
  },
  getters: {
    videoBank (state) {
      return state.videoBank
    }
  }
}
