export default {
  namespaced: true,
  actions: {

  },
  mutations: {

  },
  state: {
    langvidges: [
      {
        id: 'ru',
        name: 'Russian',
        imgsrc: 'flagRussia'
      },
      {
        id: 'en',
        name: 'English',
        imgsrc: 'flagUK'
      },
      {
        id: 'it',
        name: 'Italian',
        imgsrc: 'flagItaly'
      }
    ],
    ru:{
        myName: 'Вячеслав Кульдяев',
        langvidgeName: 'язык',
        menuTitles:{
            home: 'Главная',
            projects: 'Проекты',
            photos: 'Фото',
            video: 'Видео',
            contacts: 'Контакты',
            others: 'Разное'
        }
    },
    en:{
        myName: 'Viacheslav Kuldyaev',
        langvidgeName: 'language',
        menuTitles:{
            home: 'Home',
            projects: 'Projects',
            photos: 'Photos',
            video: 'Video',
            contacts: 'Contacts',
            others: 'Others'
        }
    },
    it:{
        myName: 'Viacheslav Kuldyaev',
        langvidgeName: 'linguaggio',
        menuTitles:{
            home: 'Casa',
            projects: 'Progetti',
            photos: 'Foto',
            video: 'Video',
            contacts: 'Contatti',
            others: 'Altro'          
        }
    },
    dict:{
      aboutText:{
        ru: 'Страницы сии созданы были мною на Vue по собственной задумке и материалам моего же производства',
        en: 'This project was created on Vue based my ideas and original materials',
        it: 'Tutti Frutti'
      }
    }
  },
  getters: {
    getFlagByLang: (state) => (lang) => {
      return state.langvidges.find(langvidge => langvidge.id === lang)
    },
    getTranslate: (state) => (meaning,lang) => {
      return state.dict[meaning][lang]
    }
  }
}
