<template>
  <footer :style="lightTheme ? 'background-color: silver;' : 'background-color: grey;'">
    <div class="context container">
      <ul id="socialIconsList" >
        <li class="socialIconsItem" v-for="(icon, index) of this.links" :key="icon.id">
          <div class="footerLink center" @mouseover="changeIconColor([icon.color, true, index])" @mouseleave="changeIconColor([ iconColorBase, false, index])">
            <a class="blank center" :href="icon.link" target="_blank">
              <iconbase :width=this.width :height=this.mywidth :iconName=icon.id :viewBox=viewBox><path :d=icon.path :fill="iconsColor[index]" /></iconbase>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import iconbase from '@/components/IconBase.vue'

export default {
  name: 'FooterMain',
  components: {
    iconbase
  },
  data () {
    return {
      width: '40px',
      viewBox: '0 0 50 50',
      iconsColor: ['grey', 'grey', 'grey', 'grey', 'grey', 'grey', 'grey', 'grey'],
      iconColorBase: 'grey'
    }
  },
  computed: {
    ...mapState({
      links: state => state.Footer.links,
      mainwidth: state=>state.Status.currWidth,
      lightTheme: state => state.Status.lightTheme,
    }),
    mywidth(){
      if(this.mainwidth < 300){
        return '20px'
      } else {
        return '40px'
      }
    }
  },
  methods: {
    changeIconColor (icon) {
      if (icon[1]) {
        this.iconsColor[icon[2]] = icon[0];
      } else {
        this.iconsColor[icon[2]] = icon[0];
      }
    }
  },
  watch: {
    lightTheme() {
      if(this.lightTheme){
        this.iconColorBase='grey';
        this.iconsColor = ['grey', 'grey', 'grey', 'grey', 'grey', 'grey', 'grey', 'grey'];

      } else {
        this. iconColorBase='silver';
        this.iconsColor = ['silver', 'silver', 'silver', 'silver', 'silver', 'silver', 'silver', 'silver'];

      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.context{
  display: flex;
  align-items: center;
  height: 100%;
}

#socialIconsList{
  margin: 0;
  padding: 0;
  max-height: 45px;
  display: flex;
}
.socialIconsItem{
  height: 100%;
  margin: 1px;
}
li{
  display: block;
}
img{
  height: 40px;
}
.footerLink{
  max-height: 43px;
  max-width: 43px;
  margin-right: 5px;
  background-size: cover;
  animation: icons-spin 1 2s linear;
}
.blank{
  height: 100%;
}
@keyframes icons-spin {
  from {
    transform: scale(1, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
@media only screen and (min-width: 251px) and (max-width: 2320px) {
  svg{
    height: 3vh;
    width: 3vh;
  }
  svg:hover{
    height: 3.5vh;
    width: 3.5vh;
  }
  .footerLink{
    height: 3.5vh;
    width: 3.5vh;
    margin-right: 1px;
  }
}
@media only screen and (max-width: 250px) {
  svg{
    height: min(3vh, 10vw);
    width: min(3vh, 10vw);
  }
  svg:hover{
    height: 3.5vh;
    width: 3.5vh;
  }
   .footerLink{
    height:  min(3.5vh, 12vw);
    margin-right: 1px;
  }
  footer{
    height: 4vh;
    
  }
}
</style>
