export default {
  namespaced: true,
  actions: {
  },
  mutations: {
    changeVisibilityLangList (state) {
      state.visibleChooseLang = !state.visibleChooseLang
    },
    changeCurrentPage (state, newPage) {
      state.activePage = newPage
    },
    changeCurrentLanguage (state, newLang) {
      state.langvidge = newLang.id
      state.langFlag = newLang.imgsrc
      state.visibleChooseLang = false
    },
    changeCurrentMeasurment (state, measurs) {
      state.currHeight = measurs[0]
      state.currWidth = measurs[1]
    },
    changeCurrentScroll (state, measur) {
      state.currScroll = measur
    },
    changeVisibilityFirstImg (state) {
      state.isFirstImgHide = !state.isFirstImgHide
    },
    changeHomeTagVisibility (state) {
      state.isHomeTagHide = !state.isHomeTagHide
    },
    changeProjectTagVisibility (state) {
      state.isProjectTagHide = !state.isProjectTagHide
    },
    changeContactTagVisibility (state) {
      state.isContactTagHide = !state.isContactTagHide
    },
    showPolaroidPhotoSmallSize (state) {
      state.polaroidSizeIndex = 0
    },
    showPolaroidPhotoMediumSize (state) {
      state.polaroidSizeIndex = 1
    },
    showPolaroidPhotoLargeSize (state) {
      state.polaroidSizeIndex = 2
    },
    changePolaroidFiltersVisibility (state) {
      state.isPolaroidFiltersShowed = !state.isPolaroidFiltersShowed
    },
    changePulse(state){
      state.pulse<501 ?state.pulse+= 1 :state.pulse=0
    },
    changeWelcomeHeight (state, measur) {
      state.homeHeights[0] = measur
    },
    changeProjectsHeight (state, measur) {
      state.homeHeights[1] = measur
    },
    changePhotosHeight (state, measur) {
      state.homeHeights[2] = measur
    },
    changeContactsHeight (state, measur) {
      state.homeHeights[3] = measur
    },
    changeCurrPage (state, page) {
      state.currPage = page
    },
    changeTheme (state) {
      state.lightTheme = !state.lightTheme
    },
    setThemeLight (state) {
      state.lightTheme = true
    },
    coloredStackIcons(state, arr) {
      state.stackIconsActive = arr
    },
    blankedStackIcons(state) {
      state.stackIconsActive = [
        false, false, false, false, false,
        false, false, false, false, false,
        false, false, false, false   
      ]
    }
  },
  state: {
    currPage: 'home',
    lightTheme: true,
    pulse: 0,
    currHeight: 0,
    currWidth: 0,
    currScroll: 0,
    homeHeights: [0, 0, 0, 0],
    stackIconsActive: [
      false, false, false, false, false,
      false, false, false, false, false,
      false, false, false, false   
    ],
    email: 'slavalion@mail.ru',
    langvidge: 'en',
    visibleChooseLang: false,
    isPolaroidFiltersShowed: true,
    polaroidSizeIndex: 2
  },
  getters: {
    pulse: (state) => state.pulse,
    do: state => {
      return !state.visibleChooseLang
    },
    myStyle: state => {
      const newStyle = {
        width: state.currHeight + 'px',
        height: state.currWidth + 'px'
      }
      if (state.currWidth > 1140) {
        if (state.currHeight > 850) {
          newStyle.width = 1140 + 'px'
          newStyle.height = 805 + 'px'
        } else {
          newStyle.width = (state.currHeight - 50) * 1.415 + 'px'
          newStyle.height = (state.currHeight - 50) + 'px'
        }
      }
      return newStyle
    },
    magicStyle: state => {
      const newStyle = {
        width: state.currHeight + 'px',
        height: state.currWidth + 'px'
      }
      if (state.currWidth > 1140) {
        if (state.currHeight > 850) {
          newStyle.width = 912 + 'px'
          newStyle.height = 609 + 'px'
        } else {
          newStyle.width = (state.currHeight - 50) * 1.497 + 'px'
          newStyle.height = (state.currHeight - 50) + 'px'
        }
      }
      return newStyle
    }
  }
}
