<template>
  <header class="container" :style="lightTheme ? 'background-color: white;' : 'background-color: black;'">
    <div class="header-left-part">
      <TitlePlace :myname='checkPageLanguage.myName'/>
    </div>
    <div class="header-right-part">
      <SettingBox :langvidge='langvidge' :langvidgeName='checkPageLanguage.langvidgeName' :themeSwitch='isThemeSwitchShowed'/>
      <NavBar :langvidge='langvidge' :menuTitles='checkPageLanguage.menuTitles'/>
      <BurgerMenu  :langvidge='langvidge'/>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import NavBar from '@/components/NavBar.vue'
import TitlePlace from '@/components/TitlePlace.vue'
import SettingBox from '@/components/SettingBox.vue'
import BurgerMenu from '@/components/BurgerMenu.vue'

export default {
  name: 'HeaderBlock',
  components: {
    NavBar,
    SettingBox,
    BurgerMenu,
    TitlePlace
  },
  computed: {
    ...mapState({
      langvidge: state => state.Status.langvidge,
      currPage: state => state.Status.currPage,
      lightTheme: state => state.Status.lightTheme,
    }),
    checkPageLanguage() {
      if (this.langvidge == 'ru'){return this.$store.state.Dictionary.ru}
      else if (this.langvidge == 'en'){return this.$store.state.Dictionary.en}
      else {return this.$store.state.Dictionary.it}
    },
    isThemeSwitchShowed(){
      if (this.currPage === 'projects'){
        return true
      } else {
        return false
      }
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header{
  height: 8vh;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
}
.header-left-part{
  height: 100%;
}
@media only screen and (max-width: 499px){
  .header-right-top, .header-right-bottom{
    display: none;
  }
  .header-right-burger{
    display: flex;
  }
  .header-left-part{
    width: 70%;
  }
  .header-right-part{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 500px) {
  .header-right-top, .header-right-bottom{
    display: flex;
  }
  .header-right-burger{
    display: none;
  }
  .header-left-part{
    width: 50%;

  }
  .header-right-part{
    width: 50%;
  }
}
@media only screen  and (min-width: 400px) and (max-width: 500px) {
  header{
    height: 6vh;
  }
}
@media only screen  and (min-width: 350px) and (max-width: 399px) {
  header{
    height: 7vh;
  }
}
@media only screen  and (min-width: 300px) and (max-width: 349px) {
  header{
    height: 5vh;
  }
}
@media only screen  and (min-width: 250px) and (max-width: 299px) {
  header{
    height: 4vh;
  }
}
@media only screen  and (min-width: 230px) and (max-width: 249px) {
  header{
    height: 5vh;
  }
}
</style>
