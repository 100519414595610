<template>
    <div class="header-right-top">
      <div class="themeTitle" v-show="themeSwitch" :style="lightTheme ? 'color:#2c3e50' : 'color: silver'">theme:</div>
      <ThemeSwitch v-show="themeSwitch"/>

      <div id="languageTitle" :style="lightTheme ? 'color:#2c3e50' : 'color: silver'">{{langvidgeName}}:</div>
      <div id="language" class="center" @click="showChangeLanguages">
        <div class="languageScreen languageScreenFirst" :style="{'background-image': 'url(' + require('@/assets/img/' + getFlagByLang(langvidge).imgsrc + '.png') + ')'}"></div>
        <div class="center languageScreen">{{langvidge}}</div>
        <div id="changeLanguageBtn" class="center languageScreen languageScreenLast">&#9660;</div>
      </div>
        
      <div class="lang-list" v-show="visibleChooseLang"> 
        <ul id="languageList" class="column">
          <li class="langItem" v-for="lang of this.langvidges" :key="lang.id" @click="changeLang(lang)">
            <div class="flagLanguage" :style="{'background-image': 'url(' + require('@/assets/img/' + lang.imgsrc + '.png') + ')'}"></div>
            <div class="langName center">{{lang.name}}</div>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ThemeSwitch from '@/components/ThemeSwitch.vue'
export default {
  name: 'SettingBox',
  props: ['langvidge', 'langvidgeName', 'themeSwitch'],
  components: {
    ThemeSwitch
  },
  computed: {
    ...mapState({
      langvidges: state => state.Dictionary.langvidges,
      visibleChooseLang: state => state.Status.visibleChooseLang,
      lightTheme: state => state.Status.lightTheme,
    }),
    ...mapGetters({
      getFlagByLang: 'Dictionary/getFlagByLang'
    })
  },
  methods: {
    showChangeLanguages () {
      this.$store.commit('Status/changeVisibilityLangList')
    },
    changeLang (newLang) {
      this.$store.commit('Status/changeCurrentLanguage', newLang)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-right-top{
  width: 99%;
  height: 66%;
  padding: 0 1% 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#language{
  width: 20%;
  height: 100%;
}
#languageTitle, .themeTitle{
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content:flex-end;
  font-size:1.8vh;
}
#languageList{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.lang-list{
  height: 9vh;
  width: 15vh;
  padding: 1vh;
  position: absolute;
  border: 1px solid grey;
  border-radius: 5px;
  z-index: 3;
  top: 5vh;
  right: 1vh;
  background-color:white;
}
.langItem{
  list-style-type: none;
  margin-bottom: 0.1vh;
  width: 100%;
  height: 33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flagLanguage{
  height: 2vh;
  width: 3vh;
  border: 1px solid grey;
  display: block;
  background-size: cover;
  margin-right: 5px;
}
.languageScreen{
  border: 1px solid grey;
  height: 2vh;
  width: 3vh;
  background-size: cover;
  font-size: 1.8vh;
}
.languageScreenFirst{
  border-radius: 2px 0 0 2px;
}
.languageScreenLast{
  border-radius: 0 2px 2px 0;
}
.langItem:hover .flagLanguage{
  height: 2.2vh;
  width: 3.3vh;
}
.langName{
  height: 2vh;
  width: 9vh;
  font-size: 1.8vh;
}
.langItem:hover .langName{
 font-weight: 550;
}

@media only screen and (max-width: 700px) {
  #languageTitle, .themeTitle{
    display: none;
  }
}
</style>
