import { createStore } from 'vuex'
import Footer from './modules/footer'
import Dictionary from './modules/dictionary'
import Photos from './modules/photos'
import Videos from './modules/videos'
import Projects from './modules/projects'
import Status from './modules/currStatus'
import Stack from './modules/stack'
import Maindata from './modules/maindata'
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    Footer,
    Dictionary,
    Photos,
    Videos,
    Projects,
    Status,
    Stack,
    Maindata,
  }
})
