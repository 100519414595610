<template>
  <Main/>
  <Footer/>
</template>

<script>
import Main from '@/components/MainPage.vue'
import Footer from '@/components/FooterMain.vue'

export default {
  components: {
    Main,
    Footer
  }
}
</script>

<style>
html, body{
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.spaceBetween{
  display: flex;
  justify-content: space-between;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.column{
  display: flex;
  flex-direction: column;
}
.container{
  position: relative;
  width: 1440px;
  margin: 0 auto;
}
.topTag, .bottomTag{
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.projectTag{
  font-size: 30px;
  font-weight: 550;
  text-decoration: none;
}
.magicTag{
  margin-left: 10px;
  padding: 3px;
}
.magic-enter-active,
.magic-leave-active {
  transition: opacity 4s ease;
}
.magic-enter-from,
.magic-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 1440px){
 .container{
    position: relative;
    width: 100%;
    margin: 0;
  }
}
@media screen and (min-width: 600px) and (max-width: 799px) {
  .projectTag{
    font-size: 25px;
    font-weight: 500;
  }
}
@media screen and (min-width: 500px) and (max-width: 599px) {
  .projectTag{
    font-size: 23px;
    font-weight: 500;
  }
}
@media screen and (min-width: 400px) and (max-width: 499px) {
  .projectTag{
    font-size: 18px;
    font-weight: 600;
  }
}
@media screen and (min-width: 300px) and (max-width: 399px) {
  .projectTag{
    font-size: 16px;
    font-weight: 600;
  }
}
@media screen and (min-width: 100px) and (max-width: 299px) {
  .projectTag{
    font-size: 14px;
    font-weight: 600;
  }
}


.btnPlace{
  width:100%;
  height: 20%;
}
.gradient{
  min-width: 50px;
  min-height: 20px;
  background: rgb(199,199,199);
  background: linear-gradient(142deg, rgba(199,199,199,1) 0%, rgba(233,233,233,1) 45%, rgba(176,176,176,1) 100%);
  background-size: 400%; 
  animation: gradient 10s infinite linear; 
}
@keyframes gradient {
  0% {background-position: 80% 0%;}
  50% {background-position: 20% 100%;}
  100% {background-position: 80% 0%;}
}
.myBtn{
  padding: 10px 50px;
  border-radius: 5px;
  border: 1px solid grey;
  text-decoration: none;
  color: grey;
}
.myBtn:hover {
  color: #727272;
  border: 1px solid #727272;
}



@font-face {
  font-family: 'Romanus';
  src: url('~@/assets/fonts/a_romanus.ttf');
  src: local('Romanus'),
    url('~@/assets/fonts/a_romanus.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'CourierR';
  src: url('~@/assets/fonts/NimbusMono-Regular.otf');
  src: local('CourierR'),
    url('~@/assets/fonts/NimbusMono-Regular.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
</style>

