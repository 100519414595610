import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import( '../views/ProjectsView.vue')
  },
  {
    path: '/project/:id',
    name: 'project',
    component: () => import('../views/ProjectView.vue')
  },
  {
    path: '/photos',
    name: 'photos',
    component: () => import( '../views/PhotosView.vue')
  },
  {
    path: '/photo/:id',
    name: 'onePhoto',
    component: () => import('../views/PhotoView.vue')
  },
  {
    path: '/videos',
    name: 'videos',
    component: () => import( '../views/VideosView.vue')
  },
  {
    path: '/video/:id',
    name: 'video',
    component: () => import('../views/VideoView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import( '../views/ContactsView.vue')
  },
  {
    path: '/others',
    name: 'others',
    component: () => import( '../views/OthersView.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/weather',
    name: 'weather',
    component: () => import('../views/WeatherView.vue')
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import('../views/ErrorPageView.vue')
  },
  {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    redirect: '/404'
  }
 /* {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about"  '../views/AboutView.vue')
  }   */
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
