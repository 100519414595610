export default {
  namespaced: true,
  actions: {
    incrementAsync ({ commit }) {
      fetch("http://svoyapi.ru/photos", {
          headers: {
            Accept: "application/json",
          }
        })
          .then((response) => response.json())
          .then((data) => {
            commit('recivePhotos', data.articles);
          })
          .catch((ex) => console.log("parsing failed", ex));
    }
  },
  mutations: {
    recivePhotos(state, photos){
      state.photoBank = state.photoBank.concat(photos)
    },
    updatePhotos (state, photos) {
      state.photos = photos
    },
    changeVisibilityMagicImg (state) {
      if (!state.isMagic1Hide) {
        state.isMagic1Hide = true
        state.isMagic2Hide = false
        state.isMagic3Hide = true
        state.isMagic4Hide = true
      } else if (!state.isMagic2Hide) {
        state.isMagic1Hide = true
        state.isMagic2Hide = true
        state.isMagic3Hide = false
        state.isMagic4Hide = true
      } else if (!state.isMagic3Hide) {
        state.isMagic1Hide = true
        state.isMagic2Hide = true
        state.isMagic3Hide = true
        state.isMagic4Hide = false
      } else if (!state.isMagic4Hide) {
        state.isMagic1Hide = false
        state.isMagic2Hide = true
        state.isMagic3Hide = true
        state.isMagic4Hide = true
      }
    }
  },
  state: {
    photos: [
      { id: 'img00001' },
      { id: 'img00002' },
      { id: 'img00003' },
      { id: 'img00004' },
      { id: 'img00005' },
      { id: 'img00006' }
    ],
    photoBank: [
      {
        id: 'first',
        sempl: '00001',
        shortname: {
          ru: 'Черепаха',
          en: 'Turtle',
          it: 'Tartaruga'
        },
        shortDescription: 'This Turtle are living on Sayshel Islands',
        lat: 55.498643,
        lng: -4.630336
      },
      {
        id: 'second',
        sempl: '00002',
        shortname: {
          ru: 'Озеро',
          en: 'Lake',
          it: 'Lago'
        },
        shortDescription: 'Lake in the middle of US'
      },
      {
        id: 'third',
        sempl: '00003',
        shortname: {
          ru: 'Закат',
          en: 'Sunset',
          it: 'Tramonto'
        },
        shortDescription: 'Sunset is the promise for tomorrow sunrise'
      },
      {
        id: 'fours',
        sempl: '00004',
        shortname: {
          ru: 'Лыжник',
          en: 'Skier',
          it: 'Sciatore'
        },
        shortDescription: 'Small rest on mountains',
        lat: 43.694725,
        lng: 40.317240
      },
      {
        id: 'fifth',
        sempl: '00005',
        shortname: {
          ru: 'Озеро',
          en: 'Lake',
          it: 'Lago'
        },
        shortDescription: 'Beautiful lake in Montana mountains'
      },
      {
        id: 'sixth',
        sempl: '00006',
        shortname: {
          ru: 'Горы',
          en: 'Mountains',
          it: 'Montagne'
        },
        shortDescription: 'Description will be added soon'
      },
      {
        id: 'seventh',
        sempl: '00007',
        shortname: {
          ru: 'Статуя Свободы',
          en: 'Statue of Liberty',
          it: 'Statua della Libertà'
        },
        shortDescription: 'Description will be added soon',
        lat: -74.044457,
        lng: 40.689054
      },
      {
        id: 'next',
        sempl: '00008',
        shortname: {
          ru: 'Бизон',
          en: 'Buffalo',
          it: 'Bufalo'
        },
        shortDescription: 'Description will be added soon'
      },
      {
        id: 'nineth',
        sempl: '00009',
        shortname: {
          ru: 'Дисней',
          en: 'Disney',
          it: 'Disney'
        },
        shortDescription: 'Description will be added soon',
        lat: -81.581200,
        lng: 28.419329
      },
      {
        id: 'ten',
        sempl: '00010',
        shortname: {
          ru: 'Река',
          en: 'River',
          it: 'Fiume'
        },
        shortDescription: 'Description will be added soon'
      },
      {
        id: 'eleven',
        sempl: '00011',
        shortname: {
          ru: 'Капитолий',
          en: 'Capitol',
          it: 'Campidoglio'
        },
        shortDescription: 'Description will be added soon',
        lat: -77.010220,
        lng: 38.889830
      },
      {
        id: 'twelve',
        sempl: '00012',
        shortname: {
          ru: 'Ледяная фигура',
          en: 'Ice sculpture',
          it: 'Figura di ghiaccio'
        },
        shortDescription: 'Description will be added soon',
        lat: -72.778771,
        lng: 44.529427
      },
      {
        id: 'Thirteen',
        sempl: '00013',
        shortname: {
          ru: 'Приэльбрусье',
          en: 'Elbruse area',
          it: 'Neei pressi di Elbrus'
        },
        shortDescription: 'SunnyDay in Elbruse area',
        lat: -72.778771,
        lng: 44.529427
      },
      {
        id: 'Fourteen',
        sempl: '00014',
        shortname: {
          ru: 'Закат Мальдивы',
          en: 'Sunset Maldives',
          it: 'Tramonto Maldive'
        },
        shortDescription: 'Sunset of Indian ocean',
        lat: -72.778771,
        lng: 444
      },
      {
        id: 'fifteen',
        sempl: '00015',
        shortname: {
          ru: 'Красная площадь',
          en: 'Red Square',
          it: 'Piazza Rossa'
        },
        shortDescription: 'Kremlin and Red Square',
        lat: -72.778771,
        lng: 444
      },
      {
        id: 'sixteen',
        sempl: '00016',
        shortname: {
          ru: 'Ягоды',
          en: 'Berries',
          it: 'Frutti di bosco'
        },
        shortDescription: 'Wild berries',
        lat: -72.778771,
        lng: 444
      },
      {
        id: 'seventeen',
        sempl: '00017',
        shortname: {
          ru: 'Эмпаер-Стейт',
          en: 'EmpiereState',
          it: 'Stato Impero'
        },
        shortDescription: 'EmpiereState Building',
        lat: -72.778771,
        lng: 444
      },
      {
        id: 'eithteen',
        sempl: '00018',
        shortname: {
          ru: 'Глаза Земли',
          en: 'Eyes of the Earth',
          it: 'Occhi della Terra'
        },
        shortDescription: 'Yellowstones',
        lat: -72.778771,
        lng: 444
      },
      {
        id: 'nineteen',
        sempl: '00019',
         shortname: {
          ru: 'Мачу-Пикчу',
          en: 'Machu Piсchu',
          it: 'Machu Picchu'
        },
        shortDescription: 'Machu Piсchu',
        lat: -72.778771,
        lng: 444
      },
      {
        id: 'twenty',
        sempl: '00020',
        shortname: {
          ru: 'Лагуна',
          en: 'Lagune',
          it: 'Laguna'
        },
        shortDescription: 'Lagune',
        lat: -72.778771,
        lng: 444
      }
    ],
    isMagic1Hide: false,
    isMagic2Hide: true,
    isMagic3Hide: true,
    isMagic4Hide: true
  },
  getters: {
    getPhotoById: (state) => (id) => {
      return state.photoBank.find(photo => photo.id === id)
    },
    photoBank (state) {
      return state.photoBank
    }
  }
}