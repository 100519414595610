export default {
  namespaced: true,
  actions: {

  },
  mutations: {

  },
  state: {
    email: 'slavalion@mail.ru',
    langvidges: [
      {
        id: 'ru',
        fullname: 'Russian',
        ru: 'Русский',
        en: 'Russian',
        imgsrc: 'flagRussia'
      },
      {
        id: 'en',
        fullname: 'English',
        ru: 'Английский',
        en: 'English',
        imgsrc: 'flagUK'
      }
    ],
    headerInfo: {
      ru: {
        name: 'Вячеслав',
        lastname: 'Кульдяев',
        langvidge: 'язык',
        home: 'главная',
        projects: 'проекты',
        photos: 'фото',
        videos: 'видео',
        other: 'прочее',
        contacts: 'контакты',
        changelang: 'сменить язык',
        imgsrc: 'flagRussia'
      },
      en: {
        name: 'Viacheslav',
        lastname: 'Kuldyaev',
        langvidge: 'language',
        home: 'main',
        projects: 'projects',
        photos: 'photos',
        videos: 'videos',
        other: 'other',
        contacts: 'contacts',
        changelang: 'change language',
        imgsrc: 'flagUK'
      }
    },
    underDevelopment: {
      ru: {
        text: 'всё ещё в разработке'
      },
      en: {
        text: 'still under development'
      }
    },
    homePageInfo: {
      ru: {
        text: 'этот сайт разработан с целью систематизировать проекты, выполненные в рамках изучения информационных технологий, и другие материалы '
      },
      en: {
        text: 'this web-site is designed to systematize projects carried out in the framework of the study of information technology, and other materials'
      }
    },
    contactPageInfo: {
      ru: {
        text: 'Отправить сообщение можно любым удобным способом из списка ниже:',
        mobile: 'сотовый',
        email: 'эл. почта',
        skype: 'скайп',
        telegram: 'телеграм',
        name: 'Имя',
        message: 'Сообщение',
        send: 'Отправить',
        printName: 'Ваше имя',
        printEmail: 'Адрес Вашей электронной почты',
        printMessage: 'Текст сообщения',
        formName: 'форма для отправки сообщения Вячеславу',
        cv: 'резюме',
        view: 'смотреть',
        download: 'загрузить',
        sent: 'отправлено',
        messForMe: 'сообщение Вячеславу'
      },
      en: {
        text: 'You can send a message in any convenient way from the list below:',
        mobile: 'mobile',
        email: 'e-mail',
        skype: 'Skype',
        telegram: 'Telegram',
        name: 'Name',
        message: 'Message',
        send: 'Send',
        printName: 'Enter Your Name',
        printEmail: 'Enter Your Email',
        printMessage: 'Enter Your Message',
        formName: 'form to send a message for Viacheslav',
        view: 'view',
        download: 'download',
        cv: 'cv',
        sent: 'sent',
        messForMe: 'the message for Viacheslav'
      }
    }
  },
  getters: {
    ruMenu: state => {
      const newMenu = {
        name: state.name.ru,
        lastname: state.lastname.ru
      }
      return newMenu
    }
  }
}