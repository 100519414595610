export default {
  namespaced: true,
  actions: {
  },
  mutations: {
    addProjects(state, newProjects){
      let controllist = [];
      state.projectsBank.map(project =>{ controllist.push(project.id) });
      let newbase = newProjects.filter(proj =>  !controllist.includes(proj.id));
      for (let i=0; i<newbase.length; i++){
        state.projectsBank.push(newbase[i]) 
      }
    }
  },
  state: {
    projectsBank: [
      {
        id: 'gggg13',
        sempl: '00013',
        year: '2022-2023',
        shortname: {
          ru: 'BriskMeal',
          en: 'BriskMeal',
          it: 'BriskMeal'
        },
        tryLink: 'http://www.briskmeal.ru',
        gitHubLink: 'https://github.com/Kuldyaev/BriskMeal-1',
        stackIconsActive: [
          true, true, true, false, true,
          true, true, true, false, false,
          false, false, true, false   
        ]
      },
      {
        id: 'gggg2',
        sempl: '00002',
        year: '2018',
        shortname: {
          ru: 'Развиваем память играя',
          en: 'Memory Game',
          it: 'Un gioco di memoria'
        },
        tryLink: '/projects/memoryGame/index.html',
        gitHubLink: 'https://github.com/Kuldyaev/memorygame',
        stackIconsActive: [
          true, true, true, false, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      },
      {
        id: 'gggg3',
        sempl: '00003',
        year: '2018',
        shortname: {
          ru: 'Аркада на vanilla JS',
          en: 'Vanilla JS Arcade Game',
          it: 'Arcada Game'
        },
        tryLink: '/projects/arcade/index.html',
        gitHubLink: 'https://github.com/Kuldyaev/arcade',
        stackIconsActive: [
          true, true, true, false, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      },
      {
        id: 'gggg4',
        sempl: '00004',
        year: '2021',
        shortname: {
          ru: 'Верстка по макету 1',
          en: 'Verstka example 1',
          it: 'Linking da codice 1'
        },
        tryLink: '/projects/verstka1/index.html',
        gitHubLink: 'https://github.com/Kuldyaev/GeekBrainStore',
        stackIconsActive: [
          true, true, true, false, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      },
      {
        id: 'gggg5',
        sempl: '00005',
        year: '2018',
        shortname: {
          ru: 'Поиск ресторанов',
          en: 'Restaraunt Review App',
          it: 'indicazione per i ristoranti'
        },
        tryLink: 'https://kuldyaev.github.io/',
        gitHubLink: 'https://github.com/Kuldyaev/kuldyaev.github.io',
        stackIconsActive: [
          true, true, true, false, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      },
      {
        id: 'gggg6',
        sempl: '00006',
        year: '2019',
        shortname: {
          ru: 'Мини-опросник',
          en: 'Would You Rather',
          it: ' che avresti scelto'
        },
        tryLink: '/projects/wyr/index.html',
        gitHubLink: 'https://github.com/Kuldyaev/W-Y-R',
        stackIconsActive: [
          true, true, true, true, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      },
      {
        id: 'gggg7',
        sempl: '00007',
        year: '2020',
        shortname: {
          ru: 'Покатушки в виртуальном мире',
          en: 'Mini NeedForSpeed',
          it: 'pista automobilistica'

        },
        tryLink: 'http://www.slavalion.ru',
        gitHubLink: 'none',
        stackIconsActive: [
          false, false, false, false, false,
          false, false, false, false, false,
          false, true, false, false   
        ]
      },
      {
        id: 'gggg8',
        sempl: '00008',
        year: '2019',
        shortname: {
          ru: 'Приложение для книголюбов',
          en: 'MyReads App',
          it: 'app per bibliofili'
        },
        tryLink: '/projects/myReads/index.html',
        gitHubLink: 'https://github.com/Kuldyaev/ReactUdacityMyReads',
        stackIconsActive: [
          true, true, true, true, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      },
      {
        id: 'gggg9',
        sempl: '00009',
        year: '2022',
        shortname: {
          ru: 'Верстка по макету 2',
          en: 'Verstka example 2',
          it: 'Linking da codice 2'
        },
        tryLink: '/projects/verstka2/index.html',
        gitHubLink: 'https://github.com/Kuldyaev/verstka_v2',
        stackIconsActive: [
          true, true, true, false, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      },
      {
        id: 'gggg10',
        sempl: '00010',
        year: '2019',
        shortname: {
          ru: 'Викторина на мобильном',
          en: 'MobileFlash',
          it: 'gioco mobile'
        },
        tryLink: '/projects/mobileFlash/index.html',
        gitHubLink: 'https://github.com/Kuldyaev/MobFlash',
        stackIconsActive: [
          true, true, true, true, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      },
      {
        id: 'gggg',
        sempl: '00001',
        year: '2021',
        shortname: {
          ru: 'Онлайн магазин',
          en: 'Online store',
          it: 'negozio online '
        },
        tryLink: '/projects/storeOnline/index.html',
        gitHubLink: 'https://github.com/Kuldyaev/GB_JS_2course',
        stackIconsActive: [
          true, true, true, false, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      },
      {
        id: 'gggg12',
        sempl: '00012',
        year: '2018',
        shortname: {
          ru: 'Мой первый web-caйт',
          en: 'My first web-site',
          it: 'il mio primo sito web'
        },
        tryLink: '/projects/myFirst/index.html',
        gitHubLink: 'none',
        stackIconsActive: [
          true, true, true, false, false,
          false, false, false, false, false,
          false, false, false, false   
        ]
      }
    ]
  },
  getters: {
    projectsBank (state) {
      return state.projectsBank
    }
  }
}
