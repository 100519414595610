<template>
  <component :is="optionComponent"/>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'

export default {
  name: 'HomeView',
  computed: {
    ...mapState({
      currHeight: state => state.Status.currHeight,
      currWidth: state => state.Status.currWidth
    }),
    optionComponent () {
      if (this.currWidth >= 800) {
        return defineAsyncComponent(() => import('./HomeBig.vue'))
      } else if (this.currWidth < 800 && this.currWidth >= 400) {
        return defineAsyncComponent(() => import('./HomeMiddle.vue'))
      } else {
        return defineAsyncComponent(() => import('./HomeSmall.vue'))
      }
    }
  },
  mounted(){
    this.$store.commit('Status/setThemeLight');
  }
}
</script>
