<template>
    <div class="header-right-burger">
        <div class="menu-button-burger" @click="isSideMenuShowed=!isSideMenuShowed">
           <iconbase :width=this.width :height=this.width :iconName=this.iconTitle :viewBox=viewBox :fill=this.fill><path :d=this.path /></iconbase>
        </div>
        <div class="topMenu column" v-if="isSideMenuShowed">
          <hr>
          <div id="nav">
            <router-link class="nav-item center" to="/">
              <div class="menuTitle center" v-on:click="isSideMenuShowed = false">
                {{this.headerInfo.home}}
              </div>
              <div class="menuItemIcon center" v-on:click="isSideMenuShowed = false">
                <iconbase :width=homeWidth :height=homeWidth :iconName=projectsTitle :viewBox=homeViewBox :fill=projectsFill><path :d=homePath /></iconbase>
              </div>
            </router-link>
            <router-link class="nav-item center" to="/projects">
              <div class="menuTitle center" v-on:click="isSideMenuShowed = false">
                {{this.headerInfo.projects}}
              </div>
              <div class="menuItemIcon center" v-on:click="isSideMenuShowed = false">
                <iconbase :width=projectsWidth :height=projectsWidth :iconName=projectsTitle :viewBox=projectsViewBox :fill=this.projectsFill><path :d=this.projectsPath /></iconbase>
              </div>
            </router-link>
            <router-link class="nav-item center" to="/photos">
              <div class="menuTitle center" v-on:click="isSideMenuShowed = false">
                {{this.headerInfo.photos}}
              </div>
              <div class="menuItemIcon center" v-on:click="isSideMenuShowed = false">
                <iconbase :width=photoWidth :height=photoWidth :iconName=photoTitle :viewBox=photoViewBox :fill=this.photoFill><path :d=photoPath /></iconbase>
              </div>
            </router-link>
            <router-link class="nav-item center" to="/videos">
              <div class="menuTitle center" v-on:click="isSideMenuShowed = false">
                {{this.headerInfo.videos}}
              </div>
              <div class="menuItemIcon center" v-on:click="isSideMenuShowed = false">
                <iconbase :width=photoWidth :height=photoWidth :iconName=photoTitle :viewBox=photoViewBox :fill=this.photoFill><path :d=videosPath /></iconbase>
              </div>
            </router-link>
            <router-link class="nav-item center" to="/contacts">
              <div class="menuTitle center" v-on:click="isSideMenuShowed = false">
                {{this.headerInfo.contacts}}
              </div>
              <div class="menuItemIcon center" v-on:click="isSideMenuShowed = false">
                <iconbase :width=contactsWidth :height=contactsWidth :iconName=photoTitle :viewBox=contactsViewBox :fill=this.photoFill><path :d=contactsPath /></iconbase>
              </div>
            </router-link>
            <router-link class="nav-item center" to="/others">
              <div class="menuTitle center" v-on:click="isSideMenuShowed = false">
                {{this.headerInfo.other}}
              </div>
              <div class="menuItemIcon center" v-on:click="isSideMenuShowed = false">
                <iconbase :width=photoWidth :height=photoWidth :iconName=photoTitle :viewBox=contactsViewBox :fill=this.photoFill><path :d=othersPath /></iconbase>
              </div>
            </router-link>
          </div>
          <hr>
          <div class="menuItem center" @click="changeLang(otherLanguage)">
          <div class="menuTitle center">
              {{this.headerInfo.changelang}}
            </div>
            <div class="menuItemIcon center">
              <div id="flagLanguage" :style="{'background-image': 'url(' + require('@/assets/img/' + this.otherLanguage.imgsrc + '.png') + ')'}"></div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import iconbase from '@/components/IconBase.vue'
import { mapState } from 'vuex'

export default {
  name: 'BurgerMenu',
  data () {
    return {
      isSideMenuShowed: false,
      width: '25px',
      iconTitle: 'menuIcon',
      viewBox: '0 0 25 25',
      fill: 'grey',
      path: 'M0,3.875c0-1.104,0.896-2,2-2h20.75c1.104,0,2,0.896,2,2s-0.896,2-2,2H2C0.896,5.875,0,4.979,0,3.875z M22.75,10.375H2 c-1.104,0-2,0.896-2,2c0,1.104,0.896,2,2,2h20.75c1.104,0,2-0.896,2-2C24.75,11.271,23.855,10.375,22.75,10.375z M22.75,18.875H2 c-1.104,0-2,0.896-2,2s0.896,2,2,2h20.75c1.104,0,2-0.896,2-2S23.855,18.875,22.75,18.875z',
      homePath: 'M29.71,15.29l-3-3h0l-10-10a1,1,0,0,0-1.42,0l-10,10h0l-3,3a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L5,15.41V29a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1V15.41l1.29,1.3a1,1,0,0,0,1.42,0A1,1,0,0,0,29.71,15.29ZM25,28H7V13.41l9-9,9,9Z',
      homeViewBox: '0 0 32 32',
      homeWidth: 25,
      photoTitle: 'photo Gallery link',
      photoWidth: 30,
      photoViewBox: '0 0 48 48',
      photoFill: 'black',
      photoPath: 'M46.627,21.895l-9.235,19.805c-0.928,1.989-3.291,2.85-5.28,1.922l-5.423-2.529  c-0.395-0.134-0.683-0.496-0.683-0.936c0-0.553,0.448-1,1-1c0.171,0,0.324,0.054,0.465,0.13l0.009-0.017l5.472,2.551  c0.994,0.464,2.177,0.034,2.64-0.961l9.235-19.805c0.464-0.995,0.033-2.176-0.961-2.64l-6.859-3.199c0,0-1.031-0.356-1.031-1.122  c0-0.552,0.447-1,1-1c0.177,0,0.335,0.059,0.479,0.139l7.251,3.381C46.693,17.542,47.554,19.906,46.627,21.895z M29.006,36h-24  c-2.209,0-4-1.791-4-4V8c0-2.209,1.791-4,4-4h24c2.209,0,4,1.791,4,4v24C33.006,34.209,31.215,36,29.006,36z M18.006,26v-1  l1.158-0.468c0.58-0.466,2.045-1.847,2.045-1.847l0.04-0.196c0.305-1.511,0.735-4.001,0.735-4.804c0-3.24-1.535-4.686-4.979-4.686  s-4.979,1.445-4.979,4.686c0,0.872,0.451,3.407,0.735,4.801l0.04,0.245c0.327,0.329,1.24,1.205,2.054,1.768L16.006,25v1  c-0.008-0.081,0,2,0,2c0,1.617-1.105,2.084-2.62,2.084c-0.001,0-0.002,0-0.003,0c-3.398,0.133-3.927,1.253-4.046,1.383  C9.23,31.626,9.113,33.648,8.998,34h16.018c-0.113-0.351-0.225-2.363-0.347-2.545c-0.047-0.056-0.493-1.219-3.963-1.369  c-1.594,0-2.7-0.468-2.7-2.086C18.006,28,18.024,25.859,18.006,26z M31.006,8c0-1.104-0.896-2-2-2h-24c-1.104,0-2,0.896-2,2v24  c0,1.104,0.896,2,2,2h1.805c0.349-0.644,0.533-3.148,0.891-3.686c0,0,0.623-2.038,5.529-2.229c0.5,0,0.75-0.313,0.75-0.793  c0-0.387,0-0.693,0-0.693c0-0.097-0.016-0.183-0.021-0.277c-0.436-0.347-0.539-0.479-1.295-1.109c0,0-1.674-1.096-1.861-2.327  c0,0-0.775-3.8-0.775-5.2c0-3.45,1.54-6.686,6.979-6.686c5.365,0,6.979,3.235,6.979,6.686c0,1.366-0.775,5.2-0.775,5.2  c-0.156,1.166-1.861,2.327-1.861,2.327c-0.678,0.629-0.985,0.894-1.374,1.148c-0.003,0.081-0.018,0.155-0.018,0.238  c0,0,0,0.307,0,0.693c0,0.479,0.25,0.793,0.75,0.793c4.98,0.214,5.604,2.229,5.604,2.229c0.357,0.537,0.542,3.042,0.892,3.686h1.804  c1.104,0,2-0.896,2-2V8z',
      projectsTitle: 'projects Gallery link',
      projectsWidth: 40,
      projectsViewBox: '0 0 100 100',
      projectsFill: 'black',
      projectsPath: 'M73.94,31.61c-7.4-.84-14.08.32-19.73,3.43a32.88,32.88,0,0,0-8.54,6.79H33.05a3,3,0,0,0-2.13.87L22.41,51a3,3,0,0,0,0,4.33l3.72,3.72a3.12,3.12,0,0,0,4.08.2,8.93,8.93,0,0,1,5.44-2.08c-.9,1.79-1.7,3.46-2.34,4.82l-.42.89,12.3,12.3.89-.42c1.4-.66,3.14-1.48,5-2.42a9.46,9.46,0,0,1-2.25,5.69,3,3,0,0,0,.2,4.08l3.54,3.54a3,3,0,0,0,4.31,0l8.51-8.51A3,3,0,0,0,66.24,75V62.4A32.86,32.86,0,0,0,73,53.86c3.11-5.68,4.27-12.32,3.43-19.74A2.83,2.83,0,0,0,73.94,31.61Zm-3.39,20.9a30.47,30.47,0,0,1-6.66,8.21l-.47.42V75a.24.24,0,0,1-.07.17l-8.5,8.5a.24.24,0,0,1-.33,0L51,80.15a.23.23,0,0,1,0-.31A13.06,13.06,0,0,0,53.84,70l-.15-2.16-1.91,1c-2.16,1.14-4.28,2.17-6,3l-9.51-9.51c.8-1.7,1.8-3.76,2.91-5.85l1-1.92L38,54.4c-.52,0-1,0-1.47,0a12.16,12.16,0,0,0-8.13,2.75.21.21,0,0,1-.31,0l-3.72-3.72a.23.23,0,0,1,0-.33l8.51-8.32a.23.23,0,0,1,.16-.07H46.92l.42-.47a30.49,30.49,0,0,1,8.22-6.67c5.15-2.82,11.26-3.87,18.07-3.1a0,0,0,0,1,0,0C74.43,41.27,73.39,47.35,70.56,52.52Z',
      videosPath: 'M43,42H5c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h38c2.209,0,4,1.791,4,4v28  C47,40.209,45.209,42,43,42z M12,8H5c-1.104,0-2,0.896-2,2v2h9V8z M23,8h-9v4h9V8z M34,8h-9v4h9V8z M45,10c0-1.104-0.896-2-2-2h-7v4  h9l0,0V10z M45,14L45,14H3v20h42l0,0V14z M45,36L45,36h-9v4h-2v-4h-9v4h-2v-4h-9v4h-2v-4H3v2c0,1.104,0.896,2,2,2h38  c1.104,0,2-0.896,2-2V36z M21.621,29.765C21.449,29.904,21.238,30,21,30c-0.553,0-1-0.447-1-1V19c0-0.552,0.447-1,1-1  c0.213,0,0.4,0.082,0.563,0.196l7.771,4.872C29.72,23.205,30,23.566,30,24c0,0.325-0.165,0.601-0.405,0.783L21.621,29.765z',
      contactsViewBox: '0 0 26 26',
      contactsWidth: 25,
      contactsPath: 'M24.4970703,19.2363281c-0.4033203-0.6582031-1.0292969-1.4248047-1.7646484-2.1591797  c-0.5322266-0.5322266-2.359375-2.2675781-3.5341797-2.2675781c-0.3769531,0-0.6162109,0.1611328-0.75,0.2949219  l-1.5644531,1.5634766c-0.0263672,0.0263672-0.1103516,0.0566406-0.2617188,0.0566406  c-0.8681641,0-2.7089844-0.9609375-4.5478516-2.7988281c-2.4521484-2.453125-3.0419922-4.5097656-2.7421875-4.8095703  l1.5625-1.5634766c0.1347656-0.1347656,0.2958984-0.3740234,0.2958984-0.75  c0.0009766-1.1757813-1.7353516-3.0029297-2.2675781-3.5351563C8.3916016,2.7353516,6.5654297,1,5.3886719,1  c-0.3759766,0-0.6152344,0.1601563-0.75,0.2949219L1.5107422,4.4228516  C0.5869141,5.3457031,0.9238281,7.1513672,2.5126953,9.7890625C3.8916016,12.078125,6.0742188,14.7617188,8.65625,17.34375  C13.2363281,21.9238281,17.8740234,25.0009766,20.1962891,25c0.578125,0,1.0419922-0.171875,1.3808594-0.5107422  l3.1279297-3.1279297C25.3818359,20.6845703,24.7158203,19.5947266,24.4970703,19.2363281z M20.5166016,23.4287109  C20.4814453,23.4628906,20.3798828,23.5,20.1962891,23.5c-1.5810547,0-5.7910156-2.5292969-10.4794922-7.2167969  c-2.4990234-2.4990234-4.6005859-5.0800781-5.9189453-7.2685547C2.3125,6.5498047,2.4521484,5.6025391,2.5712891,5.4833984  L5.515625,2.5390625C5.8994141,2.6875,6.7890625,3.2539063,7.8623047,4.328125s1.640625,1.9628906,1.7890625,2.3466797  L8.2714844,8.0556641c-1.4384766,1.4384766,0.4130859,4.6005859,2.7421875,6.9306641  c1.9980469,1.9980469,4.1474609,3.2382813,5.6083984,3.2382813c0.6826172,0,1.0957031-0.2695313,1.3222656-0.4960938  l1.3808594-1.3798828c0.3837891,0.1484375,1.2724609,0.7158203,2.3466797,1.7890625  c1.0732422,1.0732422,1.640625,1.9628906,1.7890625,2.3466797L20.5166016,23.4287109z',
      othersPath: 'M4.5 10.5c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5S6 12.825 6 12s-.675-1.5-1.5-1.5zm15 0c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5S21 12.825 21 12s-.675-1.5-1.5-1.5zm-7.5 0c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5 1.5-.675 1.5-1.5-.675-1.5-1.5-1.5z'

    }
  },
  components: {
    iconbase,
  },
  computed: {
    ...mapState({
      langvidge: state => state.Status.langvidge,
      currPage: state => state.Status.currPage,
      lightTheme: state => state.Status.lightTheme,
      langvidges: state => state.Maindata.langvidges,
    }),
    checkPageLanguage() {
      if (this.langvidge == 'ru'){return this.$store.state.Dictionary.ru}
      else if (this.langvidge == 'en'){return this.$store.state.Dictionary.en}
      else {return this.$store.state.Dictionary.it}
    },
    isThemeSwitchShowed(){
      if (this.currPage === 'projects'){
        return true
      } else {
        return false
      }
    },
    headerInfo () {
      let info = {}
      if (this.langvidge === 'ru') {
        info = this.$store.state.Maindata.headerInfo.ru
      } else {
        info = this.$store.state.Maindata.headerInfo.en
      }
      return info
    },
    otherLanguage () {
      const lang = this.langvidges.filter(lg => lg.id !== this.langvidge)
      return lang[0]
    }
  },
  methods: {
    changeLang(newLang) {
      this.isSideMenuShowed=false;
      this.$store.commit('Status/changeCurrentLanguage', newLang);
      this.$store.commit('Status/changeVisibilityLangList');
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.nav-item{
  text-decoration: none;
  color: white;
}
.header-right-burger{
    width: 7vh;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-button-burger{
    width: min(25px, 7vh);
    height: min(25px, 7vh);
}
.sideMenu{
  text-align: left;
  font-size: 5vw;
  padding: 2vw;
  padding-left: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: white;
  width: 100vw;
  height: 99vh;
  background-color: Grey;
  position: absolute;
  z-index: 8;
  left: 0;
  top: 0;
}

hr{
    width: 95%;
    margin-left: 5%;
    margin-top: 0;
    margin-bottom: 0;
}
header{
  height: 8vh;
}
.menuItem{
  width: 100%;
  min-height: 50px;
}
.nav{
    height: 100%;
}
.menuBtnPlace{
  height: 100%;
  min-width: 50px;
  margin-right: 5px;
}
.header{
  padding: 0;
}
.menuBtnPlace{
    width: 100%;
}
h1 {
  margin: 0;
  font-family: 'Romanus', serif;
  font-size: 60px;
  font-weight: bold;
  margin-left: 5px;
}
h3{
  font-family: 'Romanus', serif;
  font-size: 35px;
  color: grey;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}
.header-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 5px;
  margin-bottom: 0;
}
.header-right-top, .header-right-bottom{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0;
}
.topMenu{
    min-height: 300px;
    width: 98vw;
    background-color: white;
    position: absolute;
    top: 7vh;
   left: 2vw;
    z-index: 4;
}
.menuItemIcon{
    height: 100%;
    min-width: 60px;
}
.menuTitle{
    height: 100%;
    flex-grow: 1;
    color: black;
    font-weight: 300;
}
#flagLanguage{
  height: 20px;
  width: 25px;
  border: 1px solid grey;
  display: block;
  background-size: cover;
}
.nav-item{
  width: 100%;
  height: 100%;
  display: flex;
  text-decoration: none;
  color: black;
  margin-bottom: 10px;
}
#flagLanguage{
  height: 20px;
  width: 25px;
  border: 1px solid grey;
  display: block;
  background-size: cover;
}
@media only screen and (max-width: 206px) {
  h1{
    font-size: 15px;
  }
}
@media only screen and (min-width: 207px) and (max-width: 250px){
  h1{
    font-size: 20px;
  }
}
@media only screen and (min-width: 251px) and (max-width: 300px){
  h1{
    font-size: 25px;
  }
}
@media only screen and (min-width: 301px) and (max-width: 350px){
  h1{
    font-size: 27px;
  }
}
@media only screen and (min-width: 351px) and (max-width: 401px){
  h1{
    font-size: 30px;
  }
}
@media only screen and (min-width: 402px) and (max-width: 500px){
  h1{
    font-size: 35px;
  }
}

@media only screen  and (min-width: 400px) and (max-width: 500px) {
  .topMenu{
    top: 6vh;
  }
}
@media only screen  and (min-width: 350px) and (max-width: 399px) {
  .topMenu{
    top: 7vh;
  }
}
@media only screen  and (min-width: 300px) and (max-width: 349px) {
  .topMenu{
    top: 5vh;
  }
}
@media only screen  and (min-width: 250px) and (max-width: 299px) {
  .topMenu{
    top: 4vh;
  }
}
@media only screen  and (min-width: 230px) and (max-width: 249px) {
  .topMenu{
    top: 5vh;
  }
}
</style>
